import React from "react"
import PropTypes from "prop-types"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Sidebar from "../../components/Sidebar"
import MenuBar from "../../components/MenuBar"
import GlobalStyles from "../../styles/global"

import * as S from "./LayoutStyles"

const Layout = ({ children }) => {
  return (
    <S.Wrapper>
      <GlobalStyles />
      <TransitionPortal level="top">
        <Sidebar />
      </TransitionPortal>
      <S.Main>{children}</S.Main>
      <TransitionPortal level="top">
        <MenuBar />
      </TransitionPortal>
    </S.Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
