import { Github } from "styled-icons/boxicons-logos/Github"
import { Twitter } from "styled-icons/boxicons-logos/Twitter"
import { LinkedinSquare } from "styled-icons/boxicons-logos/LinkedinSquare"

const Icons = {
  Github,
  Twitter,
  Linkedin: LinkedinSquare,
}

export default Icons
