const links = [
  {
    label: "Github",
    url: "https://github.com/jeffersonrt",
  },
  {
    label: "Linkedin",
    url: "https://www.linkedin.com/in/jefferson-tora/",
  },
  {
    label: "Twitter",
    url: "https://twitter.com/jeffersonrtora",
  },
]

export default links
